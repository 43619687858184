import React, { useState, useEffect } from 'react'
import '../css/terms.css'
import ReactMarkdown from 'react-markdown'
import axios from 'axios';
import Config from "../Config"
import { transformImageUri } from "../util"

export default function Sourcing(props) {

    const [terms, setTerms] = useState([])

    useEffect(() => {
        axios.get(Config.strapiUrl + "/sourcing")
            .then(res => {
                setTerms(res.data.content);
                console.log(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    return (
        <div className="content terms">
            <div className="terms-content float-image">

                <ReactMarkdown transformImageUri={transformImageUri} source={terms} escapeHtml={false}></ReactMarkdown>
            </div>

        </div>
    )

}