import React, { useEffect, useState } from 'react'
import '../css/menu.css'

import '../css/foot.css'
import Mailchimp from "../components/Mailchimp"
import { Link } from "react-router-dom";
import mail from "../images/mail.svg"


export default function Foot(props) {
    const [showMail, setShowMail] = useState(false)

    useEffect(() => {
        const script = document.createElement('script');

        script.src = "//book.timify.com/widget/widget.min.js"
        script.async = true;
        script.charset = 'utf-8';
        script.type = 'text/javascript';
        script.id = "timify-widget"
        script.setAttribute("data-position", "flexible")
        script.setAttribute("data-account-id", "5f6260b738bdbb11ff3aeec7")
        document.body.appendChild(script);
        console.log("Here")
        return () => {
            document.body.removeChild(script);
        }
    }, []);
    return (
        <div>
            <div className="foot">
                <div className="menu top-menu">
                    <span className="nav-item"><Link to="/press">press</Link></span>

                    <span
                        className="timify-button"
                        data-account-id="5f6260b738bdbb11ff3aeec7"
                    >make an appointment</span>
                    <span className="nav-item"><Link to="/sourcing">acquisitions</Link></span>
                </div >
                <div onClick={e => { setShowMail(true) }} className="subscribe">
                    furniture and art in your inbox <img src={mail} alt="mail"></img>
                </div>
                <div className="brand">
                    <span className="red">atomic</span> design
            </div>

                <div className="menu bottom-menu">
                    <span className="nav-item"><Link to="/terms">terms &amp; shipping</Link></span>
                    <span className="nav-item"><Link to="/giftcertificates">gift certificates</Link></span>
                </div>

            </div >
            {showMail &&
                <Mailchimp hideCallback={e => { setShowMail(false); return false }}></Mailchimp>
            }
        </div>
    )
}