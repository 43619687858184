import React from 'react'
import '../css/news.css'
import ReactMarkdown from 'react-markdown'
import VisibilitySensor from 'react-visibility-sensor'
import {  Link } from "react-router-dom";



export default function Article(props) {
    const style = props.style
    const subject = props.subject
    const type = props.type
    const content = props.content
    const extraContent = props.extraContent
    const youTube = props.youTube
    const media = props.media
    const id = props.id

    let noImages = false;
    if(media && media[0] == null && youTube == null){
        noImages = true
    }
    return (
        <VisibilitySensor onChange={props.visibiltyCallback}>
        <div className="post">
            <div className={style}>
                
                <div {...noImages  === true ? {className:"body no-images"} : {className:"body"}}>
                    <div className="subject-post">
                    {style === "large_grey" &&
                        <div className="post-details"><ReactMarkdown source={content}></ReactMarkdown></div>
                    }
                    <div className="subject"> 
                        <span className="type">{type}</span>
                        <span className="red"> // </span>
                        <span className="subject">{subject}</span>
                    </div>
                    {style !== "large_grey" &&
                        <div className="post-details"><ReactMarkdown source={content}></ReactMarkdown></div>
                    }
                    </div>
                    <div className="extra-content"><ReactMarkdown source={extraContent}></ReactMarkdown></div>
                </div>
                <div className="media-container">
                {!youTube &&
                <div className="image">
                    {media && media[0] &&
                    <Link to={"/news/" + id}>
                    <img src={media[0]}></img>
                    </Link>
                    }
                </div>
                
                }
                {youTube &&
                <iframe 
               
                    src={youTube}
                    frameBorder="0" 
                    allow="autoplay; encrypted-media" 
                    allowFullScreen={true}
                    ></iframe>
                }
                </div>
                <div className="utility"></div>
                <div className="utility2"></div>

            </div>
            

        </div>
        </VisibilitySensor>
    )
}
