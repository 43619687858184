import React, { useState, useEffect } from 'react'
import '../css/about.css'
import ReactMarkdown from 'react-markdown'
import axios from 'axios';
import Config from "../Config"

function Single(props) {
    return (

        <div id={props.hash} className="aboutbox">
            <div className="title">{props.title}</div>
            <div className="aboutbody">
                <ReactMarkdown source={props.body}></ReactMarkdown>
            </div>
            <div className="image">
                <img alt={props.title} src={props.image}></img>
            </div>

        </div>
    )
}

export default function About(props) {

    const [abouts, setAbouts] = useState([])

    useEffect(() => {
        axios.get(Config.strapiUrl + "/abouts?_sort=id")
            .then(res => {
                setAbouts(res.data);
            })
            .catch(err => {
                console.log(err)
            })
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {

        const { hash } = window.location;
        if (hash !== '') {
            let retries = 0;
            const id = hash.replace('#', '');
            const scroll = () => {
                retries += 0;
                if (retries > 50) return;
                const element = document.getElementById(id);
                if (element) {
                    setTimeout(() => element.scrollIntoView(), 0);
                } else {
                    setTimeout(scroll, 100);
                }
            };
            scroll();
        }

    }, [])

    const rend = []
    for (const about of abouts) {
        console.log(about)
        rend.push(
            <Single
                title={about.title}
                body={about.body}
                image={Config.strapiUrl + about.image.url}
                hash={about.hash}
            ></Single>
        )
    }
    return (
        <div className="content about">

            {rend}

        </div>
    )

}