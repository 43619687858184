import React, { useEffect, useState } from 'react';
import '../css/shop.css'
import { Link } from "react-router-dom";
import Config from "../Config"
import axios from 'axios';
import assHat from '../images/DL-Atomic-Design-web-01.jpg'
import Categories from '../components/Categories'


function Shop(props) {
    let mounted = true;
    const [categories, setCategories] = useState([]);
    const [categoriesError, setCategoriesError] = useState('');

    const [archives, setArchives] = useState([]);
    const [archivesError, setArchivesError] = useState('');
    const [archivesLoading, setArchivesLoading] = useState(true);

    const [newImage, setNewImage] = useState('');
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {

        if (mounted === true) {
            axios.get(Config.ecwidUrl + "/categories?token=" + Config.ecwidToken)
                .then(res => {
                    setCategories(res.data.items);
                })
                .catch(err => {
                    setCategoriesError(err.message);
                })
        }
        return () => mounted = false;
    });



    useEffect(() => {

        if (mounted === true) {
            axios.get(Config.ecwidUrl
                + "/products?token=" + Config.ecwidToken
                + "&limit=50"
                + "&category=" + Config.ecwidSoldCatId
                + "&enabled=true")

                .then(res => {
                    setArchivesLoading(false)
                    setArchives(res.data.items)
                })
                .catch(err => {
                    setArchivesError(err.message)
                })
        }
        return () => mounted = false;
    }, [])


    useEffect(() => {
        axios.get(Config.strapiUrl + "/store")
            .then(res => {
                setNewImage(Config.strapiUrl + res.data.new_image.url);
            })
            .catch(err => {
                //setError(err.message);
            })
    }, []);


    return (
        <div className="content shop">

            <div className="shop-head">
                <div className="shop-asshat">
                    <Link to={"/store/#!/c/" + Config.ecwidNewCatId}>
                        <img src={newImage}></img>
                    </Link>
                </div>
                <div className="new-arrivals">
                    <Link to={"/store/#!/c/" + Config.ecwidNewCatId}>
                        <div className="tag">new arrivals</div>
                        <div className="button">
                            <button>SHOP NEW</button>
                        </div>
                    </Link>
                </div>
            </div>

            <h1>start browsing</h1>
            <Categories showText={true} scrollKey="cats" items={categories}></Categories>
            <h1>archives</h1>
            <Categories
                showLink={false}
                type='item' showText={false}
                scrollKey="archives"
                loading={archivesLoading}
                items={archives}></Categories>
            <div className="spacer"> </div>
        </div>
    )

}

export default Shop;
