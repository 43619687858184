import React from 'react';
import '../css/shop.css'
import { Link } from "react-router-dom";
import Config from "../Config"
import SquareLoader from "react-spinners/SquareLoader";


function Category(props) {
    let type = 'c'
    let extra_class = ''
    if (props.type === "item") {
        type = 'p'
    }
    if (props.showText === false) {
        extra_class = ' category-no-text'
    }
    const content = (<div className={"category" + extra_class}>
        <div className="category-image">
            <img src={props.image} alt={props.name} />
        </div>
        {props.showText &&
            <div className="category-name">
                {props.name}
            </div>
        }
    </div>)
    if (props.showLink === false) {
        return (
            <div>{content}</div>
        )
    } else {
        return (<Link to={"/store/#!/" + type + "/" + props.id}>
            {content}
        </Link>)
    }

}

export default function Categories(props) {
    let extra_class = ''
    if (props.showText === false) {
        extra_class = ' categories-no-text'
    }


    Math.easeInOutQuad = function (t, b, c, d) {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
    };


    function scrollLeft(element, change, duration) {
        change = change * window.innerWidth;
        var start = element.scrollLeft,
            currentTime = 0,
            increment = 20;


        var animateScroll = function () {
            currentTime += increment;
            var val = Math.easeInOutQuad(currentTime, start, change, duration);
            element.scrollLeft = val;
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    }

    let cats = [];

    if (props.loading) {
        cats = (<SquareLoader
            color={getComputedStyle(document.documentElement).getPropertyValue('--color-red')}
            css={{ 'margin': 'auto' }}
        >

        </ SquareLoader >)
    } else {

        for (const item of props.items) {

            if (Config.ecwidExcludeCatIds.includes(item.id)) {
                continue;
            }

            cats.push(
                <Category
                    key={item.id}
                    type={props.type}
                    showLink={props.showLink}
                    id={item.id}
                    image={item.originalImageUrl}
                    name={item.name}
                    showText={props.showText}
                />
            )
        }
    }
    return (
        <div id={props.scrollKey} className={"categories" + extra_class}>
            <div className="scroll-control scroll-left" onClick={function () {
                scrollLeft(document.getElementById(props.scrollKey), -.5, 200);
            }
            }>&lt;</div>
            <div className="scroll-control scroll-right" onClick={function () {
                scrollLeft(document.getElementById(props.scrollKey), .5, 200);
            }
            }>&gt;</div>

            {cats}


        </div>
    )
}