import React, { useEffect } from 'react';
import '../css/ecwid.css'
import useScript from "../components/UseScript"
import SquareLoader from "react-spinners/SquareLoader";

function Store() {
    useScript("/store.js");

    useEffect(() => {
        return () => {
            console.log("render")
            document.title = "Atomic Design"
        };
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="content store">

            <div id="ecwid_product_browser_scroller"></div>
            <div id="my-store-143868">
                <SquareLoader
                    color={getComputedStyle(document.documentElement).getPropertyValue('--color-red')}
                    css={{ 'position': 'fixed', "top": "50vh", "left": "50vw" }}
                >

                </ SquareLoader >
            </div>

        </div>
    )

}

export default Store;
