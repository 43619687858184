import React, { useEffect } from "react"
import "./App.css"
import "./Moble.css"
import News from "./pages/News"
import Home from "./pages/Home"
import Shop from "./pages/Shop"
import Store from "./pages/Store"
import About from "./pages/About"
import Terms from "./pages/Terms"
import Contact from "./pages/Contact"
import Sourcing from "./pages/Sourcing"
import Giftcertificates from "./pages/Giftcertificates"
import Head from "./components/Head"
import Foot from "./components/Foot"

import Press from "./pages/Press"

import { BrowserRouter, Switch, Route, useLocation } from "react-router-dom"
import ReactGA from "react-ga"

export const history = require("history").createBrowserHistory()

ReactGA.initialize("UA-182473720-1")

history.listen((location, action) => {
    window.scrollTo(0, 0)
})

function Pages(props) {
    const location = useLocation()

    useEffect(() => {
        ReactGA.pageview(location.pathname)
    }, [location])
    return (
        <Switch>
            <Route exact path="/news">
                <News />
            </Route>
            <Route exact path="/news/:id">
                <News />
            </Route>
            <Route exact path="/shop">
                <Shop />
            </Route>
            <Route exact path="/store">
                <Store />
            </Route>
            <Route exact path="/about">
                <About />
            </Route>
            <Route exact path="/terms">
                <Terms />
            </Route>
            <Route exact path="/contact">
                <Contact />
            </Route>
            <Route exact path="/sourcing">
                <Sourcing />
            </Route>
            <Route exact path="/giftcertificates">
                <Giftcertificates />
            </Route>
            <Route exact path="/press">
                <Press />
            </Route>
            <Route path="/press/:id">
                <Press />
            </Route>
            <Route>
                <Home />
            </Route>
        </Switch>
    )
}

function App() {
    return (
        <BrowserRouter>
            <div className="body">
                <Head></Head>

                <Pages pathname={window.location.pathname} />
                <Foot></Foot>
            </div>
        </BrowserRouter>
    )
}

export default App
