import React, { useState, useEffect } from "react"
import "../css/menu.css"
import "../css/head.css"
import { ReactComponent as Logo } from "../images/logo.svg"
import { Link, useLocation } from "react-router-dom"
import Home from "../images/home.svg"
import useScript from "../components/UseScript"
import Mailchimp from "./Mailchimp"

var resizeTimer

//Fix a resize layout bug in safari
window.addEventListener("resize", function () {
    if (navigator.vendor.includes("Apple")) {
        clearTimeout(resizeTimer)
        let head = document.getElementById("head")
        head.style.display = "none"
        resizeTimer = setTimeout(function () {
            head.style.display = "block"
        }, 10)
    }
})

function HeadRender(props) {
    const compact = props.compact
    const active = props.active
    const showMail = props.showMail
    const setShowMail = props.setShowMail

    useEffect(() => {
        if (navigator.vendor.includes("Apple")) {
            clearTimeout(resizeTimer)
            let head = document.getElementById("head")
            head.style.display = "none"
            resizeTimer = setTimeout(function () {
                head.style.display = "block"
            }, 0)
        }
    })

    return (
        <div
            id="head"
            {...(compact === true
                ? { className: "heading active" }
                : { className: "heading" })}
        >
            <div className="heading-img">
                <Link to="/">
                    <Logo></Logo>
                </Link>
                <div className="thatch"></div>
                <div className="menu">
                    <span
                        {...(active === "shop" ? { className: "active" } : {})}
                    >
                        <Link to="/shop/">shop</Link>
                    </span>
                    <span
                        {...(active === "news" ? { className: "active" } : {})}
                    >
                        <Link to="/news/">news</Link>
                    </span>
                    <span
                        className="subscribe"
                        onClick={(e) => {
                            setShowMail(true)
                        }}
                    >
                        subscribe
                    </span>

                    <span
                        {...(active === "about" ? { className: "active" } : {})}
                    >
                        <Link to="/about/">about</Link>
                    </span>

                    <span
                        {...(active === "contact"
                            ? { className: "active" }
                            : {})}
                    >
                        <Link to="/contact/">contact</Link>
                    </span>

                    <span
                        {...(active === "home"
                            ? { className: "active last" }
                            : { className: "last" })}
                    >
                        <Link to="/">
                            <img className="home-img" src={Home}></img>
                        </Link>
                    </span>
                </div>
                {compact === false && (
                    <div className="tagline">
                        furniture
                        <br /> lighting
                        <br />
                        art <span className="red">+</span> object
                    </div>
                )}
            </div>
            <Link to={"/store/#!/~/cart"}>
                <div id="mini-cart" className="mini-cart">
                    <svg
                        className="icon-default"
                        width="36"
                        height="30"
                        viewBox="0 0 36 30"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g fill="none" fillRule="evenodd">
                            <path
                                d="M7 7h22v18a4 4 0 0 1-4 4H11a4 4 0 0 1-4-4V7z"
                                stroke="currentColor"
                                strokeWidth="2"
                            ></path>
                            <path
                                d="M13 10V6c0-2.993 2.009-5 5-5s5 2.026 5 5v4"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            ></path>
                        </g>
                    </svg>
                    <div id="mini-cart-number"></div>
                </div>
            </Link>

            {showMail && (
                <Mailchimp
                    hideCallback={(e) => {
                        setShowMail(false)
                        return false
                    }}
                ></Mailchimp>
            )}
        </div>
    )
}

export default function Head(props) {
    useScript("/store_widget.js")
    const [showMail, setShowMail] = useState(false)
    const [compact, setCompact] = useState(true)
    const [active, setActive] = useState("home")

    let location = useLocation()

    useEffect(() => {
        if (location.pathname === "/") {
            setActive("home")
            setCompact(false)
        } else {
            const parts = location.pathname.split("/")
            if (parts[1]) {
                setCompact(true)
                setActive(parts[1])
            } else {
                setActive("home")
                setCompact(false)
            }
        }
    }, [location.pathname])

    return (
        <HeadRender
            compact={compact}
            active={active}
            showMail={showMail}
            setShowMail={setShowMail}
        ></HeadRender>
    )
}
