const Config = {
    //"strapiUrl": "http://localhost:1337",
    strapiUrl: "https://www.atomicdesign.ca/api/",
    ecwidUrl: "https://app.ecwid.com/api/v3/143868",
    ecwidToken: "public_Xj9rStpwg4vbmHSpkXkWWUedG8Uw8G3v",
    ecwidId: 143868,
    ecwidSoldCatId: 330581,
    ecwidExcludeCatIds: [330581, 354608, 23888026],
    ecwidNewCatId: 354608,
    mailchimpUrl:
        "https://us1.list-manage.com/subscribe?u=83a0f8a75f90251abccc50a74&id=b642b9d3e7",
}

export default Config
