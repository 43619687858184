import React, { useState, useEffect } from 'react'
import '../css/about.css'
import ReactMarkdown from 'react-markdown'
import axios from 'axios';
import Config from "../Config"
import { transformImageUri } from "../util"

function Single(props) {
    return (

        <div id={props.hash} className="aboutbox">
            <div className="title">{props.title}</div>
            <div className="aboutbody">
                <ReactMarkdown source={props.body}></ReactMarkdown>
            </div>
            <div className="image">
                <img src={props.image}></img>
            </div>

        </div>
    )
}

export default function Contact(props) {

    const [contact, setContact] = useState([])

    useEffect(() => {
        axios.get(Config.strapiUrl + "/contact?")
            .then(res => {
                setContact(res.data.content);
            })
            .catch(err => {
                console.log(err)
            })
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);



    return (
        <div className="content terms">
            <div className="terms-content">

                <ReactMarkdown transformImageUri={transformImageUri} source={contact}></ReactMarkdown>
            </div>

        </div>
    )

}