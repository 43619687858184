import React, { useState, useEffect } from 'react'
import '../css/news.css'
import '../css/press.css'

import axios from 'axios';
import Config from "../Config"
import Article from "../components/Atricle"
import { transformImageUri } from "../util"
import { useParams } from "react-router-dom";
import ReactMarkdown from 'react-markdown'

function Articles(props) {

    let posts = [];
    let pos = 1
    for (const p of props.posts) {
        pos++
        let media = []
        for (const m of p.media) {
            media.push(Config.strapiUrl + m.url)
        }
        function vis(isVisable) {
            if (props.visibiltyCallback) {
                if (isVisable) {
                    props.visibiltyCallback(pos)
                }
            }
        }

        posts.push(
            <Article key={p.subject}
                style={p.style}
                subject={p.subject}
                type={p.type}
                content={p.content}
                extraContent={p.extra_content}
                youTube={p.youtube_embed}
                media={media}
                visibiltyCallback={vis}
                id={p.id}
            >

            </Article>
        )

    }

    return (
        <div className="posts">
            {posts}
        </div >
    )

}

export default function News(props) {
    const { id } = useParams()
    if (id) {
        return (<NewsDetail id={id}></NewsDetail>)

    } else {
        return NewsList(props)
    }

}

function NewsList(props) {

    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [limit, setLimit] = useState(10);

    useEffect(() => {
        axios.get(Config.strapiUrl + "/blogs?_sort=created_at:DESC&show=1&_limit=" + limit)
            .then(res => {
                setNews(res.data);
                setLoading(false);
                console.log(res.data)
            })
            .catch(err => {
                setLoading(false)
            })
    }, [limit]);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    function vis(pos) {
        if (pos < limit + 3) {
            setLimit(limit + 10)
        }
    }

    return (
        <div className="content news">

            <h1>news <span className="red">+</span> events</h1>

            {loading &&
                <div>loading</div>
            }
            {!loading &&
                <Articles visibiltyCallback={vis} posts={news}></Articles>
            }


        </div>
    )
}

function NewsDetail(props) {
    const [news, setNews] = useState([]);

    useEffect(() => {
        axios.get(Config.strapiUrl + "/blogs/" + props.id)
            .then(res => {
                setNews(res.data);
            })
            .catch(err => {
                console.log(err)
            })
    }, [props.id]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    let media = []
    if (news.media) {
        media = news.media
    }
    const images = []
    for (const i of media) {
        images.push(
            <img alt="" src={transformImageUri(i.url)}></img>
        )
    }


    return (
        <div className="press-details">
            <div className="press-content content">
                <h1>news <span className="red">+</span> events</h1>

                <ReactMarkdown transformImageUri={transformImageUri} source={news.content}></ReactMarkdown>
                <ReactMarkdown transformImageUri={transformImageUri} source={news.extra_content}></ReactMarkdown>
                <div className="press-images">
                    {news.youTube &&
                        <iframe
                            title="video"
                            src={news.youTube}
                            frameBorder="0"
                            allow="autoplay; encrypted-media"
                            allowFullScreen={true}
                        ></iframe>
                    }
                    {images}
                </div>
            </div>
        </div>
    )
}
