import React, { useState, useEffect } from 'react'
import '../css/press.css'
import axios from 'axios';
import Config from "../Config"
import { transformImageUri } from "../util"
import { useParams, Link } from "react-router-dom";
import ReactMarkdown from 'react-markdown'


export function PressDetails(props) {
    const [press, setPress] = useState([])

    useEffect(() => {
        axios.get(Config.strapiUrl + "/presses/" + props.id)
            .then(res => {
                setPress(res.data);
            })
            .catch(err => {
                console.log(err)
            })
    }, [props.id]);

    let media = []
    if (press.media) {
        media = press.media
    }
    const images = []
    for (const i of media) {
        images.push(
            <img alt="press image" key={i.url} src={transformImageUri(i.url)}></img>
        )
    }

    return (
        <div className="press-details">
            <h2>{press.subject}</h2>
            <div className="press-content">
                <ReactMarkdown transformImageUri={transformImageUri} source={press.content}></ReactMarkdown>
                <div className="press-images">
                    {images}
                </div>
            </div>
        </div>
    )

}

export function PressList(props) {

    const [press, setPress] = useState([])

    useEffect(() => {
        axios.get(Config.strapiUrl + "/presses?_limit=100&_sort=created_at:DESC")
            .then(res => {
                setPress(res.data);
            })
            .catch(err => {
                console.log(err)
            })
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    const rend = []
    for (const p of press) {
        rend.push(
            <div key={p.id} className="press-item">
                <Link to={"press/" + p.id}>
                    <img src={transformImageUri(p.media[0].url)} />
                </Link>
            </div>
        )
    }
    return (
        <div>
            <h1>Press</h1>
            <div className="press-itmes">
                {rend}
            </div>
        </div>

    )

}

export default function Press(props) {
    let { id } = useParams();
    let content = Object;
    if (id) {
        content = (<PressDetails id={id}></PressDetails>)
    } else {
        content = PressList(props)
    }

    return (
        <div className="content press">
            {content}
        </div>
    )


}
